import React, { ReactElement, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Image } from "../utils";

const CurrencyRate = (): ReactElement => {
  const [activeKey, setActiveKey] = useState(1);

  const data = [
    {
      id: 1,
      title: "Why is there a daily $2,000 limit?",
      description:
        "Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory.",
    },
    {
      id: 2,
      title: "How long do transfers take?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .",
    },
    {
      id: 3,
      title: "How do I contact you?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .",
    },
    {
      id: 4,
      title: "How to know the update rates?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .",
    },
    {
      id: 5,
      title: "What kinds of USSD short codes are there?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .",
    },
  ];

  const handleToggle = (id: number) => {
    if (id !== activeKey) {
      setActiveKey(id);
    }
  };

  return (
    <Row noGutters style={{ paddingTop: "3rem" }}>
      <Col xs="12">
        <div
          style={{
            textAlign: "center",
            color: "#858585",
            paddingBottom: "3rem",
          }}
        >
          Currency rate charges
        </div>
      </Col>
      <Col xs="12">
        {data.map(item => (
          <div key={item.id}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  borderBottom: "solid 0.5px #d9d9d9",
                }}
                onClick={() => handleToggle(item.id)}
              >
                <div
                  style={{
                    color: "#3a3a3a",
                    paddingTop: "1.5rem",
                    paddingBottom: "1.5rem",
                  }}
                >
                  {item.title}
                </div>
                <div>
                  <Image
                    style={{ height: "1.5rem", width: "1.5rem" }}
                    src={activeKey === item.id ? "/images/minus.svg" : "/images/plus.svg"}
                  />
                </div>
              </div>
              {activeKey === item.id && (
                <p
                  style={{
                    color: "#aab3c2",
                    paddingTop: "0.5rem",
                    paddingBottom: "2rem",
                  }}
                >
                  {item.description}
                </p>
              )}
            </div>
          </div>
        ))}
      </Col>
    </Row>
  );
};
export default CurrencyRate;
