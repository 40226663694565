import React, { ReactElement } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Image } from "../utils";

const HowItWorks = (): ReactElement => (
  <>
    <Row style={{ backgroundColor: "#fff9f5" }} noGutters>
      <Col className="d-flex justify-content-center">
        <div
          style={{
            color: "#2382b6",
            paddingTop: "2rem",
            paddingBottom: "3rem",
          }}
        >
          HOW IT WORKS
        </div>
      </Col>
    </Row>
    <Row noGutters style={{ backgroundColor: "#fff9f5" }}>
      <Col md="12" lg="6">
        <Image src="/images/step-1-image.svg" />
      </Col>
      <Col md="12" lg="6">
        <Row style={{ paddingTop: "7rem" }} noGutters>
          <Col xs="2">
            <Image
              style={{ width: "3rem", height: "3rem", marginLeft: "1rem" }}
              src="/images/1.svg"
            />
          </Col>
          <Col xs="10">
            <div
              style={{
                fontSize: "1.5rem",
                color: "#535b6c",
                fontWeight: 300,
              }}
            >
              Calculate how much to send
            </div>
            <p style={{ color: "#aab3c2", paddingBottom: "4rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco .
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row noGutters style={{ paddingTop: "2rem" }}>
      <Col md="12" lg="6">
        <Image src="/images/step-2-image.svg" />
      </Col>
      <Col md="12" lg="6">
        <Row style={{ paddingTop: "9rem" }} noGutters>
          <Col xs="2">
            <Image
              style={{ width: "3rem", height: "3rem", marginLeft: "1rem" }}
              src="/images/2.svg"
            />
          </Col>
          <Col xs="10">
            <div
              style={{
                fontSize: "1.5rem",
                color: "#535b6c",
                fontWeight: 300,
              }}
            >
              Get wallet address
            </div>
            <p style={{ color: "#aab3c2", paddingBottom: "4rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco .
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row noGutters style={{ paddingTop: "2rem", backgroundColor: "#fff9f5" }}>
      <Col md="12" lg="6">
        <Image src="/images/step-3-image.svg" />
      </Col>
      <Col md="12" lg="6">
        <Row style={{ paddingTop: "9rem" }} noGutters>
          <Col xs="2">
            <Image
              style={{ width: "3rem", height: "3rem", marginLeft: "1rem" }}
              src="/images/3.svg"
            />
          </Col>
          <Col xs="10">
            <div
              style={{
                fontSize: "1.5rem",
                color: "#535b6c",
                fontWeight: 300,
              }}
            >
              Send money
            </div>
            <p style={{ color: "#aab3c2", paddingBottom: "4rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco .
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default HowItWorks;
