import React, { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { QRCode } from "react-qr-svg";

const SendMoneyButton = styled.button`
  border: 1px solid #d55f2c;
  border-radius: 100rem;
  color: #ffffff;
  padding: 0.4rem 2rem;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #d55f2c;
`;

const ReceiveMoneyButton = styled.button`
  border: 1px solid #2382b6;
  border-radius: 100rem;
  background-color: white;
  color: #d55f2c;
  padding: 0.4rem 1.5rem;
  font-size: 0.8rem;
  cursor: pointer;
`;

const ModalColoredButton = styled.button`
  border: 1px solid #d55f2c;
  color: #ffffff;
  padding: 0.4rem 2rem;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #d55f2c;
`;

const ModalOutlineButton = styled.button`
  border: 1px solid #2382b6;
  background-color: white;
  color: #d55f2c;
  padding: 0.4rem 1.5rem;
  font-size: 0.8rem;
  cursor: pointer;
`;

interface ButtonSectionProps {
  styles: Object;
}

const ButtonSection = ({ styles }: ButtonSectionProps): ReactElement => {
  const [modalState, setModalState] = useState<
    | "send-money"
    | "receive-money"
    | "send-instructions"
    | "send-enter-amount"
    | "send-notifications"
    | "send-transfer-details"
    | "close"
  >("close");

  const handleClose = () => setModalState("close");
  const handleReceiveMoney = () => setModalState("receive-money");
  const handleSendMoney = () => setModalState("send-money");
  const handleSendInstructions = () => setModalState("send-instructions");
  const handleSendEnterAmount = () => setModalState("send-enter-amount");
  const handleSendNotifications = () => setModalState("send-notifications");
  const handleSendTransferDetails = () => setModalState("send-transfer-details");

  return (
    <>
      <div style={styles}>
        <SendMoneyButton onClick={handleSendMoney}>Send money</SendMoneyButton>
        <ReceiveMoneyButton style={{ marginLeft: "0.5rem" }} onClick={handleReceiveMoney}>
          Receive money
        </ReceiveMoneyButton>
      </div>
      {/* // Modal dialog for recieve money */}
      <Modal show={modalState === "receive-money"} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: "none" }} closeButton></Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <Col xs="12">
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "#535b6c",
                  paddingBottom: "1rem",
                }}
              >
                Receive Money
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "3rem",
                }}
              >
                Share your Sendcash wallet to receive money
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "0.5rem",
                }}
              >
                Account Number
              </div>
              <div style={{ paddingBottom: "3rem" }}>
                <input type="text" className="form-control" value="0011223344" />
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "0.5rem",
                }}
              >
                Bank name
              </div>
              <div>
                <select className="form-control" id="currencyDropdown" placeholder="Select bank">
                  <option>Select bank</option>
                </select>
              </div>
              <div
                style={{
                  paddingTop: "3rem",
                  alignSelf: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ModalColoredButton>Copy wallet address</ModalColoredButton>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* // Modal dialog for send money */}
      <Modal show={modalState === "send-money"} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: "none" }} closeButton></Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <Col xs="12">
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "#535b6c",
                  paddingBottom: "1rem",
                }}
              >
                Send Money
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "3rem",
                }}
              >
                Enter recipient details
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "0.5rem",
                }}
              >
                Account Number
              </div>
              <div style={{ paddingBottom: "3rem" }}>
                <input type="text" className="form-control" value="0011223344" />
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "0.5rem",
                }}
              >
                Bank name
              </div>
              <div>
                <select className="form-control" id="currencyDropdown" placeholder="Select bank">
                  <option>Select bank</option>
                </select>
              </div>
              <div
                style={{
                  paddingTop: "3rem",
                  alignSelf: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ModalColoredButton onClick={handleSendInstructions}>Continue</ModalColoredButton>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* // Modal dialog for send instructions */}
      <Modal show={modalState === "send-instructions"} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: "none" }} closeButton></Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <Col xs="12">
              <div
                style={{
                  textAlign: "center",
                  fontSize: "0.9rem",
                  color: "#7d8893",
                  paddingBottom: "0.2rem",
                }}
              >
                Send money to
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "#535b6c",
                  paddingBottom: "0.3rem",
                }}
              >
                Recipient Name
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: 900,
                  paddingBottom: "1.5rem",
                }}
              >
                Bank Name
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "3rem",
                }}
              >
                <div style={{ color: "#7d8893" }}>1BTC = 11,000 USD</div>
                <div style={{ color: "#e16f67" }}>0.02</div>
              </div>

              <div style={{ paddingBottom: "4rem" }}>
                <div
                  style={{
                    fontSize: "0.9rem",
                    color: "#535b6c",
                    paddingBottom: "0.5rem",
                  }}
                >
                  1. Get the Bitcoin equivalent for how much you want to send
                </div>
                <div
                  style={{
                    fontSize: "0.9rem",
                    color: "#535b6c",
                    paddingBottom: "0.5rem",
                  }}
                >
                  2. Send the Bitcoin amount to the Sendcash wallet displayed
                </div>
                <div
                  style={{
                    fontSize: "0.9rem",
                    color: "#535b6c",
                    paddingBottom: "0.5rem",
                  }}
                >
                  3. The naira value will automatically be deposited into this bank account
                </div>
              </div>

              <div style={{ color: "#7d8893", fontSize: "0.9rem" }}>
                Would you like to increase your daily limit? Send an email to info@sendmoney
              </div>
              <div
                style={{
                  paddingTop: "3rem",
                  alignSelf: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ModalColoredButton onClick={handleSendEnterAmount}>Start</ModalColoredButton>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* //Modal dialog for send money enter amount */}
      <Modal show={modalState === "send-enter-amount"} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: "none" }} closeButton></Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <Col xs="12">
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "#535b6c",
                  paddingBottom: "1rem",
                }}
              >
                How much do you want to send
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "3rem",
                }}
              >
                Enter an amount between 25 and 2,000 USD
              </div>

              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "0.5rem",
                }}
              >
                Bank name
              </div>
              <div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <select className="form-control" id="currencyDropdown">
                      <option>USD</option>
                    </select>
                  </div>
                  <input type="text" className="form-control" value="100" />
                </div>
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "1rem",
                  paddingTop: "3rem",
                }}
              >
                Note: Sendmoney does not charge any fees, but the amount sent might be impacted by
                transfer fees from the external wallet services you use.
              </div>
              <div
                style={{
                  paddingTop: "3rem",
                  alignSelf: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ModalColoredButton onClick={handleSendNotifications}>Continue</ModalColoredButton>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* // Modal dialog for send notifications */}
      <Modal show={modalState === "send-notifications"} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: "none" }} closeButton></Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <Col xs="12">
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "#535b6c",
                  paddingBottom: "1rem",
                }}
              >
                Send Money
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "3rem",
                }}
              >
                You can optionally provide an email address for us to send notifications to when
                Bitcoin has been received and Naira has been sent
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "0.5rem",
                }}
              >
                Name
              </div>
              <div style={{ paddingBottom: "3rem" }}>
                <input type="text" className="form-control" value="Ofodile Charles" />
              </div>
              <div
                style={{
                  color: "#7d8893",
                  textAlign: "left",
                  fontSize: "0.9rem",
                  paddingBottom: "0.5rem",
                }}
              >
                Email
              </div>
              <div>
                <input type="text" className="form-control" placeholder="Enter email" />
              </div>
              <div
                style={{
                  paddingTop: "3rem",
                  alignSelf: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ModalColoredButton onClick={handleSendTransferDetails}>
                  Continue
                </ModalColoredButton>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* // Modal dialog for send notifications */}
      <Modal show={modalState === "send-transfer-details"} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: "none" }} closeButton></Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <Col xs="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.5rem",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <div style={{ color: "#7d8893", fontSize: "1.2rem" }}>Amount to send</div>
                <div style={{ color: "#535b6c", fontSize: "1.2rem" }}>50 USD</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "1.5rem",
                  paddingBottom: "0.5rem",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <div style={{ color: "#7d8893", fontSize: "1.2rem" }}>BTC equivalent</div>
                <div style={{ color: "#535b6c", fontSize: "1.2rem" }}>---</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "1.5rem",
                  paddingBottom: "0.5rem",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <div style={{ color: "#7d8893", fontSize: "1.2rem" }}>Name would receive</div>
                <div style={{ color: "#535b6c", fontSize: "1.2rem" }}>~--</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "1.5rem",
                  paddingBottom: "0.5rem",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <div style={{ color: "#7d8893", fontSize: "1.2rem" }}>Updating bitcoin price</div>
                <div style={{ color: "#535b6c", fontSize: "1.2rem" }}>~--</div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "2.5rem",
                  paddingBottom: "1rem",
                }}
              >
                <div>
                  <ModalOutlineButton>Edit</ModalOutlineButton>
                </div>
                <div>
                  <ModalOutlineButton>Copy BTC amount</ModalOutlineButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "2.5rem",
                  paddingBottom: "1rem",
                }}
              >
                <div>
                  <QRCode
                    width="150px"
                    height="150px"
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    value="some text"
                  />
                </div>
                <div
                  style={{
                    width: "14rem",
                    fontSize: "1.2rem",
                    color: "#7d8893",
                  }}
                >
                  Send BTC to this Sendcash wallet address and Charles will automatically receive
                  naira after confirmation. Addresses are re-usable.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "1rem",
                  paddingBottom: "3rem",
                }}
              >
                <div>
                  <ModalOutlineButton>Copy wallet address</ModalOutlineButton>
                </div>
              </div>
              <div
                style={{
                  paddingTop: "3rem",
                  alignSelf: "center",
                  fontSize: "0.9rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ModalColoredButton>BTC sent</ModalColoredButton>
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  color: "#d55f2c",
                  textAlign: "center",
                }}
              >
                How to send BTC to a wallet
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ButtonSection;
