import React, { ReactElement } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonSection from "./buttonSection";
import { Image } from "../utils";

const SendCash = (): ReactElement => (
  <>
    <Row noGutters style={{ paddingTop: "3rem" }}>
      <Col xs="12">
        <div style={{ color: "#2382b6", textAlign: "center" }}>WHY USE SENDCASH</div>
        <Row style={{ paddingTop: "2rem" }}>
          <Col md="12" lg="4">
            <Row>
              <Col className="d-flex justify-content-center" xs="12">
                <Image
                  style={{ width: "3rem", height: "3rem", marginBottom: 0 }}
                  src="/images/easy-to-find@3x.png"
                />
              </Col>
              <Col className="d-flex justify-content-center" xs="12">
                <div style={{ color: "#535b6c", fontSize: "1.5rem" }}>It's easy</div>
              </Col>
              <Col className="d-flex justify-content-center" xs="12">
                <p
                  style={{
                    color: "#aab3c2",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    textAlign: "center",
                  }}
                >
                  A multi-factor authentication solution for employees, partners, and customers
                  accessing apps, systems, and devices
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="12" lg="4">
            <Row>
              <Col className="d-flex justify-content-center" xs="12">
                <Image
                  style={{ width: "3rem", height: "3rem", marginBottom: 0 }}
                  src="/images/low-price@3x.png"
                />
              </Col>
              <Col className="d-flex justify-content-center" xs="12">
                <div style={{ color: "#535b6c", fontSize: "1.5rem" }}>It's cheap</div>
              </Col>
              <Col className="d-flex justify-content-center" xs="12">
                <p
                  style={{
                    color: "#aab3c2",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    textAlign: "center",
                  }}
                >
                  Admins can implement multi-factor authentication easily, without impacting end
                  user productivity
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="12" lg="4">
            <Row>
              <Col className="d-flex justify-content-center" xs="12">
                <Image
                  style={{ width: "3rem", height: "3rem", marginBottom: 0 }}
                  src="/images/need-for-speed@3x.png"
                />
              </Col>
              <Col className="d-flex justify-content-center" xs="12">
                <div style={{ color: "#535b6c", fontSize: "1.5rem" }}>It's fast</div>
              </Col>
              <Col className="d-flex justify-content-center" xs="12">
                <p
                  style={{
                    color: "#aab3c2",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    textAlign: "center",
                  }}
                >
                  Create intelligent policies based on login context that limit identity challenges
                  to risky login attempts
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="d-flex justify-content-center" xs="12">
        <ButtonSection styles={{ paddingBottom: "4rem" }} />
      </Col>
    </Row>
  </>
);

export default SendCash;
