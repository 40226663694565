import React, { ReactElement } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonSection from "./buttonSection";
import { Image } from "../utils";
import styled from "@emotion/styled";

const StyledHeroRow = styled(Row)`
  padding-top: 2rem;
  background-color: #fff9f5;
`;

const Hero = (): ReactElement => (
  <>
    <StyledHeroRow noGutters>
      <Col md="12" lg="5">
        <div style={{ color: "#2382b6", paddingLeft: "4rem", paddingTop: "5rem" }}>
          Using Cash App, Coinbase, or Bitcoin wallets
        </div>
        <div style={{ paddingLeft: "4rem" }}>
          <span style={{ color: "#535b6c", fontSize: "2rem" }}>Fast</span>,{" "}
          <span style={{ color: "#d55f2c", fontSize: "2rem" }}>Affordable</span>
        </div>
        <div style={{ paddingLeft: "4rem" }}>
          <span style={{ color: "#d55f2c", fontSize: "2rem" }}>Money Transfer</span>{" "}
          <span style={{ color: "#535b6c", fontSize: "2rem" }}>to Nigeria</span>
        </div>
        <p style={{ paddingLeft: "4rem", color: "#aab3c2" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco .
        </p>
        <ButtonSection styles={{ paddingLeft: "4rem", paddingBottom: "4rem" }} />
      </Col>
      <Col md="12" lg="7">
        <Image src="/images/hero-image-1.svg" />
      </Col>
    </StyledHeroRow>
    <Row noGutters>
      <Col className="d-flex justify-content-center">
        <div
          style={{
            color: "#2382b6",
            paddingTop: "2rem",
            paddingBottom: "3rem",
          }}
        >
          OUR DAILY TRANSACTIONS OFFER
        </div>
      </Col>
    </Row>
    <Row noGutters>
      <Col md="12" lg="7">
        <Image src="/images/hero-image-2.svg" />
      </Col>
      <Col md="12" lg="5">
        <p
          style={{
            color: "#535b6c",
            paddingTop: "2rem",
            marginBottom: 0,
            fontSize: "2rem",
          }}
        >
          Send up to $2,000
        </p>
        <p style={{ color: "#535b6c", fontSize: "2rem", marginBottom: 0 }}>daily to any Nigerian</p>
        <p style={{ color: "#535b6c", fontSize: "2rem", marginBottom: 0 }}>
          bank account using Bitcoin.
        </p>
        <p style={{ color: "#aab3c2", paddingBottom: "4rem" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco .
        </p>
      </Col>
    </Row>
  </>
);

export default Hero;
