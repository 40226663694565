import React, { ReactElement } from "react";
import Card from "react-bootstrap/Card";
import styled from "@emotion/styled";

const Image = styled.img`
  object-fit: contain;
  width: 3rem;
  height: 3rem;
  padding: 0.3rem;
  padding-top: 1.2rem;
  margin-bottom: 0;
  align-self: center;
`;

const StyledCard = styled(Card)`
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
`;

const StyledCardBody = styled(Card.Body)`
  color: #535b6c;
  padding: 1rem;
`;

interface FAQCardProps {
  text: string;
  imageUrl: string;
}

const FAQCard = ({ text, imageUrl }: FAQCardProps): ReactElement => (
  <StyledCard style={{ textDecoration: "none" }} as="a">
    <Image src={imageUrl} />
    <StyledCardBody>{text}</StyledCardBody>
  </StyledCard>
);

export default FAQCard;
