import React, { ReactElement } from "react";
import Layout from "../components/common/layout";
import SEO from "../components/seo";
import { Container } from "react-bootstrap";
import { Hero, HowItWorks, ExchangeRate, SendCash, FAQS, CurrencyRate } from "../components";

const IndexPage = (): ReactElement => (
  <Layout>
    {/* <SEO title="Home page" /> */}
    <Container>
      <Hero />
      <HowItWorks />
      <ExchangeRate />
      <SendCash />
      <FAQS />
      <CurrencyRate />
    </Container>
  </Layout>
);

export default IndexPage;
