import React, { ReactElement } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Line } from "react-chartjs-2";

const data = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Exchange Rate",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "white",
      borderColor: "#d55f2c",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#d55f2c",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#d55f2c",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [12, 21, 18, 11, 9, 14, 20],
    },
  ],
};

const ExchangeRate = (): ReactElement => {
  return (
    <Row noGutters style={{ paddingTop: "2rem" }}>
      <Col xs="12">
        <div style={{ color: "#535b6c", textAlign: "center", fontSize: "1.5rem" }}>
          REAL-TIME EXCHANGE RATE
        </div>
        <div style={{ color: "#aab3c2", textAlign: "center" }}>
          Sendcash syncs with Buycoins every 2 minutes to get the
        </div>
        <div style={{ color: "#aab3c2", textAlign: "center" }}>latest bitcoin price</div>
      </Col>
      <Col xs="12">
        <Row
          style={{
            backgroundColor: "#fff9f5",
            marginLeft: "2rem",
            marginRight: "2rem",
          }}
        >
          <Col lg="6" xs="12">
            <Col
              xs="12"
              style={{
                paddingLeft: "2rem",
                paddingTop: "1rem",
                color: "#a3a3a3",
              }}
            >
              1 Nigerian Naira equals
            </Col>
            <Col
              xs="12"
              style={{
                color: "#535b6c",
                fontSize: "1.5rem",
                paddingLeft: "2rem",
                paddingTop: "0.8rem",
              }}
            >
              0.00 Bitcoin
            </Col>
            <Col
              xs="12"
              style={{
                color: "#a3a3a3",
                paddingLeft: "2rem",
              }}
            >
              26 Jul, 20:35 UTC · Disclaimer
            </Col>
            <Col xs="12" style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
              <Row style={{ paddingTop: "1rem" }}>
                <Col xs="6">
                  <input type="text" className="form-control" value="0000" />
                </Col>
                <Col xs="6">
                  <select className="form-control" id="currencyDropdown">
                    <option>Nigeria Naira</option>
                  </select>
                </Col>
              </Row>
              <Row style={{ paddingTop: "1rem" }}>
                <Col xs="6">
                  <input type="text" className="form-control" value="00.00" />
                </Col>
                <Col xs="6">
                  <input type="text" className="form-control" value="Bitcoin" />
                </Col>
              </Row>
            </Col>
            <Col
              xs="12"
              style={{
                paddingLeft: "2rem",
                fontSize: "0.75rem",
                color: "#a3a3a3",
                paddingTop: "1rem",
              }}
            >
              Data provided by Morningstar for Currency and Coinbase for Cryptocurrency
            </Col>
          </Col>
          <Col
            lg="6"
            xs="12"
            style={{
              paddingLeft: "1rem",
              paddingTop: "3rem",
              paddingBottom: "2rem",
              paddingRight: "2rem",
            }}
          >
            <Line data={data} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExchangeRate;
