import React, { ReactElement } from "react";
import { Row, Col } from "react-bootstrap";
import FAQCard from "./faqCard";
import styled from "@emotion/styled";

const StyledRowContainer = styled(Row)`
  padding-top: 3rem;
  padding-bottom: 2rem;
`;

const StyledHeading = styled.div`
  color: #2382b6;
  text-align: center;
  padding-bottom: 3rem;
`;

const FAQS = (): ReactElement => (
  <StyledRowContainer noGutters>
    <Col xs="12">
      <StyledHeading>FAQS</StyledHeading>
    </Col>
    <Col className="d-flex justify-content-center" xs="12">
      <FAQCard text="How to start" imageUrl="/images/demand@3x.png" />
      <FAQCard text="What you need" imageUrl="/images/info@3x.png" />
      <FAQCard text="Currency rate" imageUrl="/images/bitcoin-cryptocurrency@3x.png" />
    </Col>
  </StyledRowContainer>
);

export default FAQS;
